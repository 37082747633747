@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Squada+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Squada+One&display=swap');


@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
}

* { 
  margin: 0; 
  padding: 0; 
  box-sizing: border-box; 
 } 


body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

 .navbar-logo-title {
  margin: 0;
  font-family: "Squada One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

  .grid-calculator{
    grid-template-columns: 2fr 1fr;
    display: grid;
    grid-template-areas: 
    "calculator-text calculation"
    "calculation-result calculation-result"
    ;
  }

  .calculator-text{
    grid-area: calculator-text;
    
    margin: auto;
  }

  .calculation{
    grid-area: calculation;
    margin: auto;
  }

  .calculation-result{
    grid-area: calculation-result;
    margin: auto;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.interactive-button {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.default-text, .hover-text {
  z-index: 2;
}

.hover-text {
  opacity: 0;
  white-space: nowrap;
}

.interactive-button:hover .default-text {
  opacity: 0;
}

.interactive-button:hover .hover-text {
  opacity: 1;
}

/* Enhanced Fire Effect */
.fire-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: radial-gradient(circle, rgba(255, 220, 0, 0.9), rgba(255, 128, 0, 0.8), rgba(255, 60, 0, 0.7), rgba(255, 0, 0, 0.5), rgba(255, 0, 0, 0.1));
  background-size: 200% 200%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.interactive-button:hover .fire-effect {
  opacity: 1;
  animation: flicker 1s infinite;
}

@keyframes flicker {
  0% {
    background-position: 50% 50%;
  }
  25% {
    background-position: 60% 40%;
  }
  50% {
    background-position: 50% 60%;
  }
  75% {
    background-position: 40% 50%;
  }
  100% {
    background-position: 50% 50%;
  }
}